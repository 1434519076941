import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";


import VueSplide from "@splidejs/vue-splide";
import VueSmoothScroll from "vue3-smooth-scroll";
import axios from "axios";
import VueAxios from "vue-axios";
import "gitart-vue-dialog/dist/style.css";
import { GDialog } from "gitart-vue-dialog";
import { plugin as dialogPlugin } from "gitart-vue-dialog";

import "animate.css"

import CopyDeck from '@/copydeck/copydeck.js'

const app = createApp(App);
app.mixin({
    methods: {
      $CD(key) {
        return CopyDeck.get(key)
      }
    }
  });
  
app.use(dialogPlugin);
app.use(VueAxios, axios);
app.use(VueSplide);
app.use(VueSmoothScroll, {
  duration: 400,
  offset: -110,
});
app.use(router);
app.component("GDialog", GDialog);
app.mount("#app");
