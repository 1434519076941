import { Field, Form } from "vee-validate";
import axios from "axios";
const baseUrl = process.env.NODE_ENV == "production" || process.env.NODE_ENV == "staging" ? "https://api.lucien.ai" : "http://localhost:3000";
export default {
  components: {
    Field,
    Form
  },
  data: function () {
    return {
      opid: undefined,
      errorState: false,
      loadingState: false,
      operation: undefined,
      switchbubble: true,
      dialogState: false,
      file: undefined,
      step: 0,
      message: undefined,
      form: {
        demande: "Séléctionnez un type de demande"
      }
    };
  },

  async mounted() {
    try {
      if (this.$route.params._id) {
        this.opid = this.$route.params._id;
        let {
          data
        } = await axios.get(baseUrl + "/api/formdata/" + this.$route.params._id);
        this.operation = data;
      } else {
        this.errorState = true;
      }
    } catch (e) {
      this.errorState = true;
    }
  },

  methods: {
    reloadform() {
      window.location.href = '/' + this.$route.params._id;
    },

    uploadImage() {
      const file = document.querySelector("input[type=file]").files[0];
      const reader = new FileReader();
      let rawImg;

      reader.onloadend = () => {
        rawImg = reader.result;
        this.file_data = rawImg;
        console.log(this.file_data);
      };

      reader.readAsDataURL(file);
      this.file = file;
    },

    isRequired(value) {
      return value ? true : "Ce champ est requit";
    },

    validateEmail(value) {
      if (!value) {
        return "Ce champ est requit";
      }

      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

      if (!regex.test(value)) {
        return "Vous devez entrer un email valide";
      }

      return true;
    },

    onSubmit(values) {
      try {
        this.loadingState = true;
        if (this.form.demande === 'Problème technique lors de ma participation') var type = 'problem';
        if (this.form.demande === "Le site pour participer à l'opération ne fonctionne pas") var type = 'problem';
        if (this.form.demande === "Je n'arrive pas à suivre le statut de ma participation sur la page dédiée") var type = 'problem';
        if (this.form.demande === "Je n'ai pas reçu mon lot") var type = 'incident';
        if (this.form.demande === "Je n'ai pas reçu le virement bancaire") var type = 'incident';
        if (this.form.demande === "Le montant du virement bancaire reçu n'est pas le bon") var type = 'incident';
        if (this.form.demande === "Je n'ai pas reçu l'email de confirmation de ma participation") var type = 'problem';
        if (this.form.demande === "Question générale à propos de l'opération") var type = 'question';
        if (this.form.demande === "Je ne comprends pas pourquoi ma participation est invalide") var type = 'question';
        if (this.form.demande === "Question à propos du produit acheté") var type = 'question';
        var formDatas = {
          operationId: this.$route.params._id,
          nom: values.nom,
          prenom: values.prenom,
          email: values.email,
          demande: this.form.demande,
          objet: values.objet,
          message: this.form.message,
          file: this.file,
          idgame: this.operation.idgame,
          // file_data : this.file_data,
          type: type
        };
        this.axios.post(baseUrl + "/api/operations-custom/promodev-send-contact/sendmail", formDatas).then(response => {
          this.loadingState = false;
          this.dialogState = true;
        });
      } catch (e) {
        console.log(e);
        this.loadingState = false;
      }
    },

    nextStep() {
      this.switchbubble = false;
      this.step = this.step + 1;
      if (this.step > 3) this.step = 1;
      setTimeout(() => {
        this.switchbubble = true;
      }, 200);
    },

    prevStep() {
      this.step = this.step - 1;
      if (this.step < 0) this.step = 3;
    }

  }
};